@import "../../styles/variables.module";

.container {
  padding: 50px var(--gutters);
  // just in case there isn't browser support
  height: 90vh;
  height: 90svh;
  width: 100%;

  display: flex;
  justify-content: center;

  color: $omega-text-two;
  background-color: $omega-background-one;
  font-size: 7vw;

  position: relative;
  overflow: hidden;
}

.content {
  max-width: var(--maxWidth);
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.textContainer {
  display: flex;
  flex-direction: column;
}

.focused {
  font-size: 17vw;
  font-weight: bold;
  line-height: 0.95;
  margin: 0.4rem 0 1.2rem;
  color: $omega-accent-three;
}

.bouncy {
  -webkit-box-reflect: below -20px linear-gradient(transparent, rgba(0, 0, 0, 0.2));
}

.bouncy span {
  display: inline-block;
  animation: bouncy 2s infinite;
  animation-delay: calc(0.1s * var(--i));
}

@keyframes bouncy {
  0%,
  40%,
  100% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-5px);
  }
}

@media screen and (min-width: 744px) {
  .focused {
    font-size: 7vw;
  }
  .container {
    font-size: 3vw;
  }
}
@media screen and (min-width: 1440px) {
  .container,
  .focused {
    font-size: 350%;
  }
}
