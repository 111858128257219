@import "../styles/variables.module";

.app {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: $alpha-background-one;
  color: $alpha-text-two;

  h1,
  h2 {
    color: $alpha-text-one;
  }
}

.app::after {
  display: block;
  content: " ";
  height: 100px;
  width: 100%;
  background-color: $omega-background-one;
}

a {
  color: inherit;
  text-decoration: unset;
}
a:hover {
  text-decoration: underline;
}

// controls the fade-in animations for content
@media (prefers-reduced-motion: no-preference) {
  :global(.hidden) {
    opacity: 0;
    transition:
      opacity 1s,
      filter 1s,
      transform 1s;
    transform: translateX(-100%);
  }

  :global(.hiddenStatic) {
    opacity: 0;
    filter: blur(2px);
    transition:
      opacity 1s,
      filter 1s;
    transition-delay: 0.75s;
  }

  :global(.visible) {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
  }
}
