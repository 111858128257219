@import "../../styles/variables.module";

.container {
  margin-bottom: 50px;
}

.content {
  padding: 150px var(--gutters);

  display: flex;
  justify-content: center;
  position: relative;
}

.textContainer {
  max-width: var(--maxWidth);
  width: 100%;
  z-index: 3;
}
