@import "../../styles/variables.module";

// keep track because it is used more than once
$height: 65px;

:export {
  navHeight: $height;
}

.nav {
  width: 100%;
  height: $height;
  font-size: $font-small;

  display: flex;
  justify-content: center;

  padding-right: 1rem;
  padding-left: 1rem;

  z-index: 10;
  box-shadow: 0 3px 6px 0 $box-shadow-one;
  border-top: 2px solid $alpha-accent-two;
  background-color: $alpha-background-one;
}

.navFixed {
  @extend .nav;

  position: fixed;
  top: 0;
  left: 0;

  border-top: none;

  // we want to add margin to the next element to offset
  // the tabs becoming fixed at top of screen
  + section {
    margin-top: calc($height);
  }
}

.root {
  max-width: var(--maxWidth);
  width: 100%;

  // avoid putting margin on last tab
  .trigger:last-of-type {
    margin-right: 0;
  }
}

.trigger {
  background-color: unset;
  color: $alpha-accent-two;
  height: $height;

  border: none;
  cursor: unset;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-right: 0.75rem;
  border-bottom: 5px solid transparent;
  cursor: pointer;
  transition:
    background-color 0.2s,
    opacity 0.2s;
}

.trigger:hover {
  background-color: $alpha-background-one-hover;
  opacity: 0.9;
}

.selectedTrigger {
  @extend .trigger;

  border-bottom: 5px solid $alpha-accent-two;
}

@media screen and (min-width: 744px) {
  .nav {
    padding: 0 var(--gutters);
    font-size: $font-medium;
  }

  .trigger {
    margin-right: 1.25rem;
  }
}
