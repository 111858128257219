.container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;

  > svg {
    position: absolute;

    // see comment at keyframes
    g {
      stroke-dasharray: 1000;
      stroke-dashoffset: 2520;
    }
  }

  > svg:first-child {
    top: 0px;
    left: -30px;
    width: 150px;
    height: 150px;
    opacity: 0.9;
  }

  > svg:last-child {
    bottom: 0;
    left: 60%;
    width: 150px;
    height: 75px;
    opacity: 0.9;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .container > svg g {
    animation-name: draw;
    -webkit-animation-name: draw;
    animation-duration: 40s;
    -webkit-animation-duration: 40s;
    animation-direction: normal;
    -webkit-animation-direction: normal;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    animation-delay: calc(var(--order) * 1s);
    -webkit-animation-delay: calc(var(--order) * 1s);
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
  }

  .container > svg:last-child g {
    animation-duration: 25s;
    -webkit-animation-duration: 25s;
  }

  // TODO: can't use calc in keyframes, have to use
  // magic number until I find a clean solution
  @keyframes draw {
    0% {
      stroke-dasharray: 1000;
    }
    25% {
      stroke-dasharray: 900;
    }
    50% {
      stroke-dasharray: 1000;
    }
    75% {
      stroke-dasharray: 1440;
    }
    100% {
      stroke-dasharray: 1000;
    }
  }
}

@media screen and (min-width: 744px) {
  .container > svg:first-child {
    width: 200px;
    height: 200px;
    left: -60px;
  }

  .container > svg:last-child {
    width: 250px;
    height: 125px;
  }
}
