@import "../../styles/variables.module";

.container {
  padding: 2rem var(--gutters);
  // just in case there isn't browser support
  height: 10vh;
  height: 10svh;

  display: flex;
  justify-content: center;

  font-size: 5vw;
  background-color: $omega-background-one;
  color: $omega-text-two;
  overflow: hidden;
}

.content {
  max-width: var(--maxWidth);

  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  margin: 1rem;
  width: 5vw;
  height: 5vw;
  // causes the icons to slowly move up and down
  animation: mover 1s infinite alternate;
  -webkit-animation: mover 1s infinite alternate;
}

// causes the icons to slowly move up and down
@keyframes mover {
  0% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(-5px);
  }
}

// causes the icons to slowly move up and down
@-webkit-keyframes mover {
  0% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(-5px);
  }
}

@media screen and (min-width: 744px) {
  .container {
    font-size: 3vw;
  }

  .icon {
    width: 3vw;
    height: 3vw;
  }
}

@media screen and (min-width: 1440px) {
  .container {
    font-size: 300%;
  }

  .icon {
    width: 50px;
    height: 50px;
  }
}
