@import "../../styles/variables.module";

$imgOffset: 10px;

.container {
  display: flex;
  justify-content: center;

  padding: 100px var(--gutters) 100px;
  position: relative;
}

.content {
  width: 100%;
  max-width: var(--maxWidth);
  z-index: 3;
}

.content > :first-child {
  margin-top: 25px;
}

.imageContainer {
  position: relative;

  display: flex;
  justify-content: flex-end;

  img {
    border-radius: $borderRadius;
    box-shadow:
      -$imgOffset 10px $alpha-accent-two-transparent,
      0 4px 4px 0 $box-shadow-one;
  }

  margin-bottom: 50px;
}

.imageContainer > img:first-child {
  width: 90%;
  height: auto;

  margin-bottom: 50px;
}

.imageContainer > img:last-child {
  // overlapping the images
  position: absolute;
  bottom: 0;
  left: $imgOffset;

  width: 50%;
  height: auto;
}

@media screen and (min-width: 744px) {
  .imageContainer {
    justify-content: center;
    height: 450px;
  }

  .imageContainer > img:first-child {
    position: absolute;
    width: 450px;
    left: 35%;
  }

  .imageContainer > img:last-child {
    width: 300px;
    left: unset;
    right: 50%;
  }
}
