body {
  margin: 0;
  font-family: "Manrope", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  /* just in case there isn't browser support */
  height: 100vh;
  height: 100svh;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;

  --gutters: 50px;
  --maxWidth: 100%;
}

@media screen and (min-width: 744px) {
  :root {
    --gutters: 100px;
    --maxWidth: 1200px;
  }
}
