@import "../../styles/variables.module";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px var(--gutters);

  position: relative;
  color: $omega-text-one;

  text-decoration: none;
  background-color: $omega-background-one;
}

.container::before {
  top: 0;
  right: 0;
  position: absolute;
  content: " ";
  width: 100%;
  height: 100%;
  background-image: url("../../../public/github-mark.svg");
  background-repeat: no-repeat;
  background-position-x: 135%;
  background-position-y: 20%;
  background-size: 50%;
  opacity: 0.5;
}

.container:hover {
  text-decoration: none;
}

.content {
  width: 100%;
  max-width: var(--maxWidth);
  z-index: 2;
}

.headerContainer {
  display: flex;

  span {
    display: block;
  }
}

.profile {
  width: 45px;
  height: 45px;
  border-radius: 50px;

  + div {
    margin-left: 1rem;
  }
}

.projectName {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.github {
  width: 50%;
  height: auto;

  top: 10%;
  right: 0;
  position: absolute;
  overflow: hidden;
}

.link {
  color: $omega-accent-one;
}

/* airbnb breakpoint */
@media screen and (min-width: 744px) {
  .container {
    background-position-x: 100%;
    background-position-y: 35%;
    background-size: 35%;
  }
}
