/** Color variables are divided into sections labeled by: **/
/** greekAlphabet-purpose-number **/

/** Alpha is the primary and default color scheme of the site, used for text sections **/
$alpha-background-one: #fefff1;
$alpha-background-one-hover: rgba(0, 0, 0, 0.05);
$alpha-text-one: #1f2836;
$alpha-text-two: #33677c;
$alpha-text-three: #618b9c;
$alpha-accent-one: #acd3fa;
$alpha-accent-one-transparent: rgba(227, 129, 30, 0.5);
$alpha-accent-two: #33677c;
$alpha-accent-two-transparent: rgba(50, 102, 123, 0.5);
$alpha-accent-three: #1f2836;
$alpha-accent-three-transparent: rgba(32, 41, 55, 0.5);
$alpha-input-border: #b4b4b4;

/** Omega is the and dark-mode color scheme of the site **/
$omega-background-one: #1f2836;
$omega-background-one-hover: rgba(255, 255, 255, 0.05);
$omega-text-one: #fefff1;
$omega-text-two: #e0e0df;
$omega-accent-one: #70b5fa;
$omega-accent-one-transparent: rgba(112, 181, 250, 0.5);
$omega-accent-two: #33677c;
$omega-accent-two-transparent: rgba(51, 103, 124, 0.5);
$omega-accent-three: #aad4ff;

// util
$box-shadow-one: rgba(0, 0, 0, 0.25);
$controls-dark-one: rgba(0, 0, 0, 0.5);
$controls-dark-two: rgba(0, 0, 0, 0.4);
$controls-dark-three: rgba(0, 0, 0, 0.3);

/** Structural **/
$borderRadius: 4px;

/** Font sizes **/
$font-xsmall: 14px;
$font-small: 16px;
$font-medium: 24px;
$font-large: 32px;
$font-xlarge: 48px;
$font-xxlarge: 72px;
$font-xxxlarge: 128px;

// allows the export of scss variables to be used in components
:export {
  font-xsmall: $font-xsmall;
  font-small: $font-small;
  font-medium: $font-medium;
  font-large: $font-large;
  font-xlarge: $font-xlarge;
  font-xxlarge: $font-xxlarge;
}
