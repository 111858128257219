@import "../../styles/variables.module";

.accordion {
  width: 100%;
  background-color: $omega-background-one;
  color: $omega-text-one;
}

.accordion > div:first-child button {
  border: none;
}
