@import "../../../styles/variables.module";

.header {
  padding: 0 var(--gutters);

  display: flex;
  justify-content: center;

  margin: 0;
  border-top: 2px solid $omega-accent-two;
  transition: background-color 0.2s;
}

.header:hover {
  background-color: $omega-background-one-hover;
}

.trigger {
  max-width: var(--maxWidth);
  width: 100%;

  padding: 1.5rem 0 1.75rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: unset;
  border: none;
  width: 100%;
  cursor: pointer;
  color: $omega-text-two;
}

.labels {
  text-align: left;

  span {
    display: block;
  }
}

.labels > span:first-child {
  color: $omega-text-one;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.labels > span:nth-child(2) {
  font-size: $font-small;
  margin-bottom: 0.25rem;
}

.labels > span:last-child {
  font-size: $font-small;
}

.content {
  padding: 0.5rem var(--gutters);
  border-top: 2px solid $omega-accent-two;
  transition: all 1s;
  overflow: hidden;
  color: $omega-text-two;

  p {
    animation: fadeIn 3s;
  }
}

.bullets {
  max-width: var(--maxWidth);
  width: 100%;
  margin: 0 auto;
}

.bullets > p::before {
  content: "- ";
}

.content[data-state="open"] {
  animation: expand 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

.content[data-state="closed"] {
  animation: close 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

.trigger svg {
  width: 25px;
  height: 25px;
  transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

.trigger[data-state="open"] svg {
  transform: rotate(180deg);
}

@keyframes expand {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes close {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
