@import "../../../styles/variables.module";

.form {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  width: 100%;
  margin: 20px 0;

  input,
  button {
    width: 100%;
  }

  input {
    margin-bottom: 1em;
  }

  input {
    border-radius: $borderRadius;
    border: 1px solid $alpha-input-border;
    padding: 8px;
  }

  input::placeholder {
    color: $alpha-input-border;
  }
}

.inputContainer {
  display: flex;
  flex-direction: column;

  width: 100%;
}

.submit {
  margin-top: 10px;
  color: white;
  background-color: $alpha-accent-three;
  cursor: pointer;
  transition: 1.25s;
}

.submit:hover {
  // add animation to button
  // I try not to use vw but % doesn't work here
  box-shadow: inset 100vw 0 0 0 $alpha-accent-three-transparent;
  background-color: $alpha-accent-three-transparent;
}

@media screen and (min-width: 1440px) {
  .inputContainer {
    flex-direction: row;
    justify-content: space-between;
  }

  .shortInput {
    max-width: 49%;
  }
}
