@import "../../styles/variables.module";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  padding: 150px var(--gutters);
}

.content {
  width: 100%;
  max-width: var(--maxWidth);

  z-index: 3;
}

.contentCentered {
  @extend .content;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.icon {
  color: green;
  width: $font-xxxlarge;
  height: $font-xxxlarge;
}
