@import "../../styles/variables.module";

$headingHeight: 80px;
$containerPadding: 150px;
$cardHeightMobile: 240px;
$cardHeightDesktop: 310px;
$transitionTime: 0.2s;

// we calculate the offset to ensure the non-selected skills are at the right height
:export {
  headingHeight: calc($headingHeight + $containerPadding);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  position: relative;

  width: 100%;
  padding: $containerPadding 0;
  color: $alpha-text-one;

  h2 {
    display: flex;
    align-items: center;
    height: $headingHeight;
    margin: 0;
    margin-bottom: $cardHeightMobile;
  }
}

.card {
  position: absolute;
  transition: right 0.5s ease-in-out;
  width: 70%;
  background-color: $alpha-background-one;
  border-radius: $borderRadius;
  box-shadow: 0 0 8px 0 $box-shadow-one;
  height: $cardHeightMobile;
  transition:
    height $transitionTime,
    background-color $transitionTime,
    margin $transitionTime,
    right 0.5s ease-in-out;

  z-index: 2;

  .body {
    padding: 1.5rem;
  }

  span {
    font-size: $font-xsmall;
    font-weight: bold;
    text-transform: uppercase;
  }

  h3 {
    font-size: $font-medium;
    margin-top: 0;
    margin-bottom: 1rem;
  }
}

.card:hover {
  text-decoration: none;
  background-color: $alpha-background-one-hover;
  // to add the effect of the card expanding
  margin: -5px 0;
  height: calc(#{$cardHeightMobile} + 12px);
}

.selectedCard {
  position: unset;

  @extend .card;
}

.imgContainer {
  width: 100%;
  height: 100px;
  overflow: hidden;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;

  border-radius: $borderRadius $borderRadius 0 0;
  transition: opacity $transitionTime;
}

.card:hover .img {
  opacity: 0.9;
}

.controls {
  position: absolute;

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 90%;
  // we want controls height to be 60% of cards, not heading
  height: calc($headingHeight + 60%);
}

.icon {
  $dimension: 25px;

  background-color: $controls-dark-one;
  color: $omega-text-one;
  height: $dimension;
  width: $dimension;
  border-radius: $borderRadius;
  border: 0;
  box-shadow: 0 0 8px 0 $box-shadow-one;
  padding: 0;
  cursor: pointer;
  z-index: 3;

  svg {
    width: $dimension;
    height: $dimension;
  }
}

.icon:hover {
  background-color: $alpha-accent-two;
}

.icon:disabled {
  background-color: $controls-dark-two;
  color: $controls-dark-three;
  cursor: unset;
}

@media screen and (min-width: 744px) {
  .container h2 {
    margin-bottom: $cardHeightDesktop;
  }

  .card {
    width: 35%;
    height: $cardHeightDesktop;
  }

  .card:hover {
    height: calc(#{$cardHeightDesktop} + 12px);
  }

  .imgContainer,
  .img {
    height: 200px;
  }
}

@media screen and (min-width: 1440px) {
  .card {
    width: 25%;
  }
}
