@import "../../styles/variables.module";

$heroHeight: 25vw;
$avatarDimension: 125px;

.container {
  position: relative;

  color: $omega-text-two;
  background-color: $omega-background-one;

  width: 100%;

  span {
    display: inline-block;
    margin-bottom: 1rem;
  }
}

.heroContainer {
  display: flex;
  justify-content: center;

  width: 100%;
  height: $heroHeight;
  overflow: hidden;
  background-color: #003375;
}

.hero {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: var(--maxWidth);
}

.avatar {
  // calculate to keep it even with the gutters
  left: var(--gutters);
  top: calc(#{$heroHeight} - #{$avatarDimension} / 2);
  position: absolute;
  border: 5px solid $omega-background-one;
  border-radius: 100%;
  // negate the effect of the border
  margin: -5px;

  width: 125px;
  height: 125px;
}

.body {
  padding: 6rem var(--gutters) 3rem;
  display: flex;
  justify-content: center;
}

.content {
  max-width: var(--maxWidth);
  width: 100%;

  span {
    display: block;
  }
}

span.name {
  color: $omega-text-one;
  font-size: $font-medium;
  font-weight: bold;
  margin-bottom: 0.25rem;
}

span.description {
  color: $omega-text-one;
}

span.muted {
  font-size: $font-xsmall;
}

a.button {
  display: flex;
  align-items: center;
  padding: 0;
  line-height: 0;
  cursor: pointer;
  font-weight: bold;
  background-color: $omega-accent-one;
  color: $omega-background-one;
  border: 0;
  border-radius: $borderRadius;
  margin-top: 1rem;
  width: fit-content;
  text-decoration: none;
  transition: 0.75s;

  .imgContainer {
    height: 100%;
    padding: 1rem;
    background-color: rgba(0, 0, 0, 0.1);
  }

  span {
    line-height: unset;
    padding: 1rem;
    margin: 0;
  }
}

a.button:hover {
  // add animation to button
  box-shadow: inset 60vw 0 0 0 $omega-accent-one-transparent;
  text-decoration: none;
  background-color: $omega-accent-one-transparent;
}

@media screen and (min-width: 1440px) {
  .avatar {
    left: calc((100% - var(--maxWidth)) / 2);
  }
}
